
import {defineComponent, PropType} from 'vue';
import {IApiImageData} from "@/global/api"
import {useStore} from "vuex"
import {key} from "@/store"

export default defineComponent({
  name: 'WatchComponent',

  components: {},

  data() {
    return {
      store: useStore(key)
    }
  },

  props: {
    data: {
      type: Object as PropType<IApiImageData>,
      required: true,
    }
  },

  computed: {
    name(): string {
      return this.data.safeName.replace( 'nc-level-one-' , '')
    }
  },

  methods: {
    openImage() {
      this.store.commit('setImageToEasyLightBoxImage', this.data.url)
    }
  }
});
