
import { defineComponent } from 'vue';
import WatchComponent from "@/components/WatchComponent.vue";
import {apiGet, IApiResponse_certification, IApiResponse_certificationLogin} from "@/global/api"
import FormLockedPage, {IValidateData} from "@/components/FormLockedPage.vue"
import {key} from "@/store"
import {useStore} from "vuex"

export default defineComponent({
  name: 'Certification',
  components: {
    FormLockedPage,
    WatchComponent
  },

  mounted() {
    this.updateCertificationData()
  },

  data() {
    return {
      certificationData: undefined as undefined | IApiResponse_certification | {error: string},
      store: useStore(key),
    }
  },

  computed: {
    certificationLoginData(): IApiResponse_certificationLogin {
      return {
        titleName:      this.store.state.certificationLoginData?.titleName      || '',
        description:    this.store.state.certificationLoginData?.description    || '',
        image:          this.store.state.certificationLoginData?.image          || null,
        certifications: this.store.state.certificationLoginData?.certifications || [],
      }
    }
  },

  methods: {
    formValidate(e: IValidateData) {
      this.store.commit("changeCertificationConnection", {id: e.id, password: e.password} as IValidateData)
      this.updateCertificationData()
    },

    async updateCertificationData() {

      if (this.store.state?.componentUnlocked !== null) this.certificationData = await apiGet("certification",
          {
            id: this.store.state.componentUnlocked.id,
            password: this.store.state.componentUnlocked.password
          }) as IApiResponse_certification | { error: string }

      else this.certificationData = undefined
    },

    clearCertificationID() {
      this.store.commit("changeCertificationConnection", null)
      this.updateCertificationData()
    }
  }

});
